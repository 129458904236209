import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';

const Index = () => {
  const section1 = useRef();
  const section2 = useRef();
  const section3 = useRef();
  const section4 = useRef();
  const section5 = useRef();

  const scrollHandler = (elmRef) => {
    window.scrollTo({ top: elmRef.current.offsetTop, behavior: "smooth" });
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);

  const form = useRef();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm('service_kbybyve', 'template_o8nu607', form.current, {
        publicKey: 'sg_cbhxu8eWliyU2_',
      })
      .then((response) => {
        console.log('Email sent:', response.status);
        setStatus('success');
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: ''
        });
      },)
      .catch((error) => {
        console.error('Error sending email:', error);
        setStatus('error');
      })
      .finally(() => {
        setTimeout(() => {
          setStatus('');
        }, 6000);
        setLoading(false);
      });
  };

  return (
    <>
      <section className="h-h">
        <header>
          <div className="container">
          <nav>
            <img src="../images/logo.svg" alt="" />
            <ul>
              <li onClick={() => scrollHandler(section1)}>
                <Link to="#">Home</Link>
              </li>
              <li onClick={() => scrollHandler(section2)}>
                <Link to="#">About us</Link>
              </li>
              <li onClick={() => scrollHandler(section3)}>
                <Link to="#">Expertise</Link>
              </li>
              <li onClick={() => scrollHandler(section4)}>
                <Link to="#">Services</Link>
              </li>
              <li onClick={() => scrollHandler(section5)}>
                <Link to="#">Our client speak</Link>
              </li>
            </ul>
          </nav>
          </div>
        </header>
        <div className="hero" ref={section1}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="hero-heading">
                  <h1>
                    Empower Your Business with Seamless Automation and{" "}
                    <span>Integration</span> using <span>Workato</span> platform
                  </h1>
                  <button data-bs-toggle="modal" data-bs-target="#contactModal" >Contact us</button>
                </div>
              </div>
              <div className="col-md-6 d-none d-md-block">
                <div className="h-s">
                  <img
                    src="../images/banner-list.svg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about" ref={section2}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-h">
                <h3 className="heading">About Estrata</h3>
                <h4>Unlocking the Power of Workato Integration Since 2020</h4>
                <p>
                  We're proud to be recognized as premier Workato experts,
                  dedicated to helping organizations worldwide streamline their
                  operations through seamless automation and integration
                  solutions.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="boxes-items">
                <div className="item">
                  <div className="items-h">
                    <h3>Reliable and Technically Savvy</h3>
                    <p>
                      With a team of seasoned professionals, we pride ourselves
                      on our reliability and technical process.
                    </p>
                  </div>
                  <img src="../images/icon-first.svg" alt="" />
                </div>
                <div className="item">
                  <div className="items-h">
                    <h3>Reliable and Technically Savvy</h3>
                    <p>
                      With a team of seasoned professionals, we pride ourselves
                      on our reliability and technical process.
                    </p>
                  </div>
                  <img src="../images/icon-4.svg" alt="" />
                </div>
                <div className="item">
                  <div className="items-h">
                    <h3>Reliable and Technically Savvy</h3>
                    <p>
                      With a team of seasoned professionals, we pride ourselves
                      on our reliability and technical process.
                    </p>
                  </div>
                  <img src="../images/icon-2.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="expertise" ref={section3}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 px-md-0">
              <div className="exp-img">
                <img src="../images/woman.png" alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="exp-h">
                <h3 className="heading">Our Expertise</h3>
                <p>
                  At Estrata Cloud, we've honed our expertise through the
                  successful development and execution of numerous projects
                  across various domains. While each project is unique, our deep
                  understanding and experience in specific areas enable us to
                  deliver exceptional value to our clients:
                </p>
              </div>
              <div className="boxes-listing">
                <div className="new-box">
                  <h3>E-commerce Integration Solutions</h3>
                  <p>
                    Seamlessly connect e-commerce platforms like Magento and
                    Shopify with ERP/CRM systems for enhanced efficiency.
                  </p>
                </div>
                <div className="new-box">
                  <h3>E-commerce Integration Solutions</h3>
                  <p>
                    Seamlessly connect e-commerce platforms like Magento and
                    Shopify with ERP/CRM systems for enhanced efficiency.
                  </p>
                </div>
                <div className="new-box">
                  <h3>E-commerce Integration Solutions</h3>
                  <p>
                    Seamlessly connect e-commerce platforms like Magento and
                    Shopify with ERP/CRM systems for enhanced efficiency.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="service" ref={section4}>
        <div className="container">
          <div className="row">
            <h3 className="heading">Our Services</h3>
            <div className="col-md-6">
              <div className="card-create">
                <img src="../images/card-1.png" alt="" />
                <div className="card-list">
                  <h3>Auto-pilot: Perfect for Business Teams</h3>
                  <ul>
                    <li>
                      Ideal if you're new to Workato and lack in-house resources
                      for workflow implementation
                    </li>
                    <li>
                      Benefit from a partner who understands your business
                      processes, identifies, and implements automations across
                      various departments.
                    </li>
                    <li>
                      Suitable for small to mid-sized projects, offering
                      fixed-price options for a streamlined procurement process.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-create">
                <img src="../images/card-2.png" alt="" />
                <div className="card-list">
                  <h3>Auto-pilot: Perfect for Business Teams</h3>
                  <ul>
                    <li>
                      Ideal if you're new to Workato and lack in-house resources
                      for workflow implementation
                    </li>
                    <li>
                      Benefit from a partner who understands your business
                      processes, identifies, and implements automations across
                      various departments.
                    </li>
                    <li>
                      Suitable for small to mid-sized projects, offering
                      fixed-price options for a streamlined procurement process.
                    </li>
                    <li>
                      Suitable for small to mid-sized projects, offering
                      fixed-price options for a streamlined procurement process.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="client-speak" ref={section5}>
        <div className="container">
          <div className="client">
            <div className="row">
              <div className="col-md-5">
                <h3 className="start">Start Your Journey With Us</h3>
              </div>
              <div className="col-md-7">
                <div className="jor-heading">
                  <p>
                    Talk to us to determine the right model for you and learn
                    about our pricing!
                  </p>
                  <button className="button-submit" data-bs-toggle="modal" data-bs-target="#contactModal" >
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="f-m">
                <img src="../images/logo.svg" alt="Estrata Cloud" width="360" />
              </div>
            </div>
            <div className="col-md-4">
              <div className="list-f">
                <ul>
                  <li onClick={() => scrollHandler(section1)}>
                    <Link to="#">Home</Link>
                  </li>
                  <li onClick={() => scrollHandler(section2)}>
                    <Link to="#">About us</Link>
                  </li>
                  <li onClick={() => scrollHandler(section3)}>
                    <Link to="#">Expertise</Link>
                  </li>
                  <li onClick={() => scrollHandler(section4)}>
                    <Link to="#">Services</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="email">
                <Link to="mailto:info@estratacloud.com">
                  info@estratacloud.com
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="modal fade" id="contactModal" tabIndex="-1" aria-labelledby="contactModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="contactModalLabel">Contact Us</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {status === 'error' && <div className="alert alert-danger" role="alert">Please fill in all required fields.</div>}
              {status === 'success' && <div className="alert alert-success" role="alert">Message sent successfully!</div>}


              <form ref={form} onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">Name *</label>
                  <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Email *</label>
                  <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required />
                </div>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">Phone</label>
                  <input type="tel" className="form-control" id="phone" name="phone" value={formData.phone} onChange={handleChange} />
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">Message *</label>
                  <textarea className="form-control" id="message" name="message" rows="5" value={formData.message} onChange={handleChange} required></textarea>
                </div>
                {loading ? (
                  <button type="button" className="btn disabled button-submit d-block ms-auto mt-4" disabled>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    Submitting...
                  </button>
                ) : (
                  <button type="submit" className="button-submit d-block ms-auto mt-4">Send</button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Index;
